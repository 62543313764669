import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { imageLoadedStatus } from '../../misc/utility.js'

import Animation from '../Animation'


//Component
class AnimationIntro extends Component {

  state = {
    height: '100vh',
    titles: [],
    currentTitle: 0,
    glitching: false,
    delayTimer: 3000,
    glitchTimer: 1000
  };

  componentDidMount = () => {

    let { titles } = this.props.content
    let titlesArray = titles.map((node, index) => {
      return node.title
    }) 

    let height = window.innerHeight
    window.addEventListener('resize', this.updateWindowDimensions)

    this.setState({
      titles: titlesArray,
      height: height,
      currentTitle: titlesArray.length
    }, () => this.glitch() )
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout1)
    clearTimeout(this.timeout2)
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    if ( typeof window !== 'undefined' ) {
      this.setState({
        height: window.innerHeight
      })
    }
  }

  glitch = () => {
    let { currentTitle, titles, glitchTimer, delayTimer } = this.state
    currentTitle = currentTitle + 1 < titles.length ? currentTitle + 1 : 0

    this.setState(
      {
        currentTitle: currentTitle,
        glitching: false
      },
      () => {
        this.timeout1 = setTimeout(() => {
          this.setState(
            {
              glitching: true
            },
            () => {
              this.timeout2 = setTimeout(() => {
                this.glitch()
              }, glitchTimer)
            }
          )
        }, delayTimer)
      }
    )
  }

  render() {

    return (
      <StaticQuery 
        query={graphql`
          query introLogo {
            allWordpressAcfOptions {
              edges {
                node {
                  options {
                    logo {
                      alt_text
                      localFile {
                        childImageSharp {
                          fixed(width: 200) {
                            ...Fixed
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}

        render = {data => {

          const { top_heading, scroll_text } = this.props.content
          const { titles, currentTitle, glitching } = this.state
          const logo = data.allWordpressAcfOptions.edges[0].node.options.logo
          const fixed = ( logo.localFile && logo.localFile.childImageSharp ) ? logo.localFile.childImageSharp.fixed : null

          let titleClasses = [
            'glitch',
            'size-not-so-epic-heading',
            glitching ? 'glitching' : null
          ]

          //Image console
          imageLoadedStatus('AnimationIntro.js', (!!fixed), logo.alt_text)


          return (
            <div ref={(element) => { this.element = element }} className="component component-AnimationIntro" style={{height: this.state.height}}>

              <Animation />

                <div className="static-intro">

                  {( top_heading ) ? (
                    <h1 className="font-main size-h2-heading text-uppercase" dangerouslySetInnerHTML={{__html: top_heading }}/>
                  ) : null}

                  {(fixed) ? (
                    <Img className="logo-intro" fixed={fixed} alt={logo.alt_text} />
                  ) : null}

                </div>


              {(titles) ? (
                <div className="glitch-container">
                  <h2 className={titleClasses.join(" ")} data-text={titles[currentTitle]}>
                    {titles[currentTitle]}
                  </h2>
                </div>
              ) : null}


              {(scroll_text) ? (
                <p className="text-prompt" dangerouslySetInnerHTML={{__html: scroll_text }} />
              ) : null}


            </div>
          )
        }}
      />
    )
  }
}


//Props validation
AnimationIntro.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default AnimationIntro