import * as THREE from 'three'

function Cube() {
	
	this.getRandomIntFromRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

	//Set variables for cube
	this.build = ( args ) => {
		
		args = args || {}
		
		this.size = args.size || this.getRandomIntFromRange(this.minSize, this.maxSize)
		this.cube.scale.x = this.size/10
		this.cube.scale.y = this.size/10
		this.cube.scale.z = this.size/10
		
		this.vy = args.vy || (this.getRandomIntFromRange(50, 250)/750)
		this.rx = args.rx || (this.getRandomIntFromRange(-100, 100)/100000)
		this.ry = args.ry || (this.getRandomIntFromRange(-100, 100)/100000)
		this.rz = args.rz || (this.getRandomIntFromRange(-100, 100)/100000)

		this.cube.position.x = args.posX || this.getRandomIntFromRange(this.spawnArea.left, this.spawnArea.right)
		this.cube.position.y = args.posY || this.getRandomIntFromRange(this.spawnArea.bottom-(this.maxSize*1.5), this.spawnArea.top-(this.maxSize*1.5))
		this.cube.position.z = args.posZ || this.getRandomIntFromRange(this.spawnArea.far, this.spawnArea.near)
		
		this.cube.rotation.x = args.rotX || this.getRandomIntFromRange(0, 360)
		this.cube.rotation.y = args.rotY || this.getRandomIntFromRange(0, 360)
		this.cube.rotation.z = args.rotZ || this.getRandomIntFromRange(0, 360)

		this.friction = 1.19
		this.cube.gravity = false

		this.direction = new THREE.Vector3(0, 1, 0)
		this.vector = this.direction.clone().multiplyScalar(this.vy, this.vy, this.vy)
	}
	
	//Init cube
	this.init = ( args ) => {
		
		args = args || {}
		
		this.minSize = args.minSize || 10
		this.maxSize = args.maxSize || 20
		this.spawnArea = args.spawnArea || {
			left: 500,
      right: 500,
      bottom: 500,
      top: 500,
      far: 50,
      near: 0
		}
		
		//Prepare cube
		this.geometry = new THREE.BoxBufferGeometry(10, 10, 10)
		this.material = new THREE.MeshLambertMaterial({ 
			color: args.color || '#5b2160',
			emissive: '#130714'
		})
		
		//Create cube
		this.cube = new THREE.Mesh( 
			this.geometry, 
			this.material
		)
		
		this.cube.stayInPlace = false
		this.build( args )


		return this.cube
	}
	
	//Animate cube
	this.animate = () => {
		
		//Rotation
		this.cube.rotation.x += this.rx
		this.cube.rotation.y += this.ry
		this.cube.rotation.z += this.rz
		
		//Axis movement
		if ( this.cube.gravity ) {

			this.vector.y = ( this.vector.y >= 30 ) ? this.vector.y : this.vector.y * this.friction
			this.cube.position.y -= this.vector.y;

		} else if ( !this.cube.stayInPlace ) {

			this.cube.position.y += this.vector.y

		}
		
		//Respawn at bottom of viewport
		if ( 
					this.cube.position.y >= this.spawnArea.top+(this.maxSize*1.5) || 
					this.cube.position.y < this.spawnArea.bottom - this.maxSize 
				) {
			 this.build({
				 posY: this.spawnArea.bottom-(this.maxSize)
			 })
		}
	}
}


export { Cube as default}