import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LinkCTA from '../LinkCTA'


//Component
class HeadingGroupsCta extends Component {

  headingSocialLink = ( heading ) => {
    
    heading = heading ? heading : ''

    //Test text for email adresses to wrap with a link
    const mail_regex = /[a-zA-ZÅÄÖåäö–0-9-_.]{2,}[@][a-zA-ZÅÄÖåäö–0-9-_.]{2,}[.][a-zA-ZÅÄÖåäö–0-9-_.]{2,8}/gi
    if ( heading ) {
      if ( heading.match(mail_regex) ) {
        return 'mailto:'+heading
      }
    }

    //Test contact_details for phone numbers to wrap with a link
    const phone_regex = /[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{5,}|[+0-9]{5,}/gi

    if ( heading ) {
      if ( heading.match(phone_regex) ) {
        const link = heading.replace([' ','–','-'], '')
        return 'tel:'+link
      }
    }

    return ''
  }

  heading = (heading, size, className) => {

    const HeadingSize = ( size ) ? size : 'h2'
    const font_family = ( this.props.content.font_family === 'RoadRage' ) ? 'font-header' : 'font-main'

    const classes = [
      'color-purple-action',
      'size-epic-heading',
      font_family
    ]

    if ( className ) {
      classes.push(className)
    }

    return <HeadingSize className={classes.join(' ')} dangerouslySetInnerHTML={{__html: heading }}/>
  }

  subHeading = (sub_heading_content, size) => {

    const SubHeadingSize = 'h' + ( parseInt(size.replace('h','')) + 1 )

    const classes = [
      'size-h2-heading',
      'font-main'
    ]

    const heading = ( sub_heading_content ) ? sub_heading_content.title : null
    const raw_link = ( sub_heading_content ) ? sub_heading_content.url : null
    const link = ( raw_link && raw_link.slice(-'://tom'.length) !== '://tom'  ) ? raw_link : this.headingSocialLink(heading)
    const target = ( sub_heading_content ) ? sub_heading_content.target : null

    if ( link && link.slice(-'://tom'.length) !== '://tom' ) {
      return <LinkCTA title={heading} to={link} target={target} className="size-h2-heading fill-color" background={true}/>

    } else {
      return <SubHeadingSize className={classes.join(' ')} dangerouslySetInnerHTML={{__html: heading }}/>
      
    }
  }

  render() {

    const { heading_group } = this.props.content

    return (
      <div className="component component-HeadingGroupsCta">
        <div className="container-fluid">

          {heading_group ? heading_group.map((node, index) => {

            const {heading, heading_size, sub_headings} = node
            const heading_mb = ( sub_headings ) ? 'mb-2' : ''

            return (
              <span key={index} className="heading-group-container">
                
                {this.heading( heading, heading_size, heading_mb)}

                {sub_headings ? sub_headings.map((sub_node, sub_index) => {

                  const {sub_heading_content} = sub_node

                  return (
                    <span key={sub_index}>

                      {this.subHeading(sub_heading_content, heading_size)}

                    </span>
                  )
                }) : null}
                
              </span>
            )
          }) : null}

        </div>
      </div>
    )
  }
}


//Props validation
HeadingGroupsCta.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default HeadingGroupsCta