import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'
import TextLinks from '../TextLinks'
import LinkDynamic from '../LinkDynamic'


//Component
const ExtendedCta = ( props ) => {

  const content = props.content
  const HeadingSize = ( content.heading_size ) ? content.heading_size : 'h2'
  const alignment = ( content.alignment ) ? 'text-'+content.alignment : 'text-left'
  const { text, links } = content

  function heading() {

    const font_family = ( content.font_family === 'RoadRage' ) ? 'font-header' : 'font-main'
    const heading_classes = "color-purple-action size-epic-heading "+font_family

    return <HeadingSize className={heading_classes} dangerouslySetInnerHTML={{__html: props.content.heading }} />
  }

  return (
    <div className="component component-ExtendedCta">
      <div className={"container-fluid "+alignment} >

        {heading()}

        {text ? (
          <Text>    
            <TextLinks content={text} />
          </Text>
        ) : null}

        {links ? (
          <nav className="nav list-nav mb-0 mt-2">
            <ul className="d-inline-block nav__list">
              {links.map( (node, index) => {

                const item = ( node.node ) ? node.node.link : node.link

                return (
                  <li key={index} className="nav__item">
                    <LinkDynamic to={item.url} target={item.target}>
                      {item.title}
                    </LinkDynamic>
                  </li>
                )

              })}
            </ul>
          </nav>
        ) : null}

      </div>
    </div>
  )
}


//Props validation
ExtendedCta.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default ExtendedCta