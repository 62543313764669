import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BackgroundHover from './BackgroundHover'
import Video from './Video'
import PlayIcon from './PlayIcon'


//Component
class VideoPlay extends Component {

  handleVideo = () => {
    this.v.playFullscreen()
  }

	render () {

    const { className, placeholderSrc, placeholderRatio, videoSrcs } = this.props

		return (
			<div 
        className={className}
        style={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingBottom: 100/placeholderRatio+'%'
        }}
      >
        <Video 
          ref={r => (this.v = r)}
          absolute={true}
          srcs={videoSrcs}
        />

        <div 
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer'
          }}
          onClick={() => this.handleVideo()}
          onMouseEnter={() => this.p.handleHover(true)}
          onMouseLeave={() => this.p.handleHover(false)}
          onMouseDown={() => this.p.handleFocus(true)}
          onMouseUp={() => this.p.handleFocus(false)}
        >
          
          <BackgroundHover 
            image_url={placeholderSrc} 
            hover="zoom"
          />
          
          <PlayIcon 
            ref={r => (this.p = r)}
            size={75}
            child={true}
            onClick={() => this.handleVideo()}
           />
        </div>
      </div>
  	)
	}
}


//Props validation
VideoPlay.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default VideoPlay