import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'
import TextLinks from '../TextLinks'


//Component
const TextBlock = ( props ) => {

  const {title, two_cols, text, heading_size, font_family} = props.content


  function heading() {

    const HeadingSize = ( heading_size === 'h1' ) ? 'h2': heading_size;

    const heading_classes = [
      ( heading_size === 'h1' ) ? 'size-h1-heading' : '',
      ( font_family === 'RoadRage' ) ? 'font-header' : 'font-main'
    ]

    if ( title ) {
      return <HeadingSize className={heading_classes.join(' ')} dangerouslySetInnerHTML={{__html: title }} />

    } else {
      return null

    }
  }

  return (
    <div className="component component-Text">
      <div className="container-fluid">
        <div className="row">
          <div className="col text-center">

            {title ? ( 

              <Text
                two_cols={two_cols}
                lg_text={true}
                className="mb-2 text-left"
              >
                {heading()}
              </Text>

            ) : null}

            <div className="clearfix" />

            {text ? ( 

              <Text
                two_cols={two_cols}
                lg_text={true}
                className="text-left"
              >
                <TextLinks content={text} />
              </Text>

            ) : null}

          </div>
        </div>
      </div>
    </div>
  )
}


//Props validation
TextBlock.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default TextBlock