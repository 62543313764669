import React from 'react'
import PropTypes from 'prop-types'
import LinkCTA from '../LinkCTA'
import Article from '../Article'


//Component
const Articles = ({ content, componentName }) => {

  const posts = ( content.posts ) ? content.posts : null
  const link = ( componentName === 'Inspo' ) ? '/artiklar' : '/press'

  return (
    <div className="component component-Articles">
      <div className="container-fluid">

        <div className="row">

          {posts ? posts.map( (node , index) => {
            return (
              <Article key={index} {...node} />
            )
          }) : null}

        </div>
        

        {content.link_title ? (
          <div className="row">
            <div className="col-12">
              <LinkCTA 
                title={content.link_title} 
                to={link}
                className="mt-3"
                background={true}
              />
            </div>
          </div>
        ) : null}

      </div>
    </div>
  )
}


//Props validation
Articles.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Articles