import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LinkCTA from '../LinkCTA'


//Component
class HeadingCta extends Component {

  headingSocialLink = ( heading ) => {
    
    heading = heading ? heading : ''

    //Test text for email adresses to wrap with a link
    const mail_regex = /[a-zA-ZÅÄÖåäö–0-9-_.]{2,}[@][a-zA-ZÅÄÖåäö–0-9-_.]{2,}[.][a-zA-ZÅÄÖåäö–0-9-_.]{2,8}/gi
    if ( heading ) {
      if ( heading.match(mail_regex) ) {
        return 'mailto:'+heading
      }
    }

    //Test contact_details for phone numbers to wrap with a link
    const phone_regex = /[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{5,}|[+0-9]{5,}/gi

    if ( heading ) {
      if ( heading.match(phone_regex) ) {
        const link = heading.replace([' ','–','-'], '')
        return 'tel:'+link
      }
    }

    return ''
  }

  heading = () => {

    const content = this.props.content
    const HeadingSize = ( content.heading_size ) ? content.heading_size : 'h2'
    const font_family = ( content.font_family === 'RoadRage' ) ? 'font-header' : 'font-main'
    const heading_classes = "color-purple-action size-epic-heading "+font_family

    return <HeadingSize className={heading_classes} dangerouslySetInnerHTML={{__html: this.props.content.heading }} />
  }

  subHeading = () => {

    const content = this.props.content
    const HeadingSize = ( content.heading_size ) ? content.heading_size : 'h2'

    if ( content.sub_heading_content ) {

      const sub_heading = ( content.sub_heading_content ) ? content.sub_heading_content.title : null
      const SubHeadingSize = 'h' + ( parseInt(HeadingSize.replace('h','')) + 1 )
      const sub_heading_target = ( content.sub_heading_content ) ? content.sub_heading_content.target : null
      const raw_link = ( content.sub_heading_content ) ? content.sub_heading_content.url : null
      const sub_heading_link = ( raw_link && raw_link.slice(-'://tom'.length) !== '://tom'  ) ? raw_link : this.headingSocialLink(sub_heading)

      if ( sub_heading_link && sub_heading_link.slice(-'://tom'.length) !== '://tom' ) { 

        return <LinkCTA title={sub_heading} to={sub_heading_link} target={sub_heading_target} className="fill-color mt-2" background={true} />

      } else if ( sub_heading ) {

        return <SubHeadingSize className="font-main mt-2 size-h2-heading text-uppercase" dangerouslySetInnerHTML={{__html: sub_heading }} />

      }
    }

    return
  }

  render () {

    const content = this.props.content
    const alignment = ( content.alignment ) ? 'text-'+content.alignment : 'text-left'

    return (
      <div className="component component-HeadingCta">
        <div className={"container-fluid "+alignment} >

          {this.heading()}
          {this.subHeading()}

        </div>
      </div>
    )
  }
}


//Props validation
HeadingCta.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default HeadingCta