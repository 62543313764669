import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css' 
import 'slick-carousel/slick/slick-theme.css'


//Component
const SliderFull = ({className, children}) => {

  const settings = {
    className: className,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
  	<Slider {...settings}>
  		{children}
  	</Slider>
  )
}


//Props validation
SliderFull.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default SliderFull