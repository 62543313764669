import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LightboxContent from './LightboxContent'
import ImageHover from './ImageHover'
import CoworkerFull from './CoworkerFull'
import { imageLoadedStatus } from '../misc/utility.js'

//Component
class Coworker extends Component {
  
  state = {
    hover: false
  }

  handleHover = (hover) => this.setState(
    { 
      hover: hover 
    }
  )

  render() {

    const props = this.props
    const acf = props.acf
    const name = ( props.post_title ) ? props.post_title : null
    const title = ( props.acf.label ) ? props.acf.label : null
    const mail = ( acf.mail ) ? acf.mail : null
    const phone = ( acf.phone ) ? acf.phone : null
    const about = ( acf.about ) ? acf.about : null
    const main_image_src = ( acf.main_image && acf.main_image.localFile ) ? acf.main_image.localFile.childImageSharp.fixed : null
    const vertical_image = ( acf.vertical_image && acf.vertical_image.localFile  ) ? acf.vertical_image : null

    let classes = [
      'coworker-item'
    ]

    const imageClassName = ( this.state.hover ) ? 'trigger-hover' : null 

    //Image console
    imageLoadedStatus('Coworker.js', (!!main_image_src), acf.main_image.alt )
    imageLoadedStatus('Coworker.js', (!!vertical_image), acf.vertical_image.alt )

    return (
      <div className={classes.join(' ')}>

        <div className="coworker-item__inner">

          <span
            onMouseEnter={() => this.handleHover(true)}
            onMouseLeave={() => this.handleHover(false)}
          >
            <LightboxContent
              modal={
                <CoworkerFull
                  name={name}
                  title={title}
                  mail={mail}
                  phone={phone}
                  about={about}
                  vertical_image={vertical_image}
                />
              }
            >

            <ImageHover 
              fixed={main_image_src} 
              hoverStyle="zoom"
              className={imageClassName}
              style={{
                position: 'absolute',
                top: 0,
                width: '100%', 
                height: 0, 
                paddingBottom: '90%'
              }}
              imgStyle={{
                width: '100%', 
                height: '100%'
              }}
            />

            <div className="coworker-item__labels">
              <h3 className="font-main coworker-item__heading" dangerouslySetInnerHTML={{__html: name }}/>
              <h4 className="font-main coworker-item__title" dangerouslySetInnerHTML={{__html: title }}/>
            </div>

            </LightboxContent>
          </span>

        </div>
      </div>
    )
  }
}


//Props validation
Coworker.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Coworker