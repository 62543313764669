import React, {Component} from 'react'
import PropTypes from 'prop-types'
import BackgroundHover from '../BackgroundHover'
import Video from '../Video'
import PlayIcon from '../PlayIcon'
import { imageLoadedStatus } from '../../misc/utility.js'

//Component
class VideoFullwidth extends Component {

  handleVideo = () => {
    this.v.playFullscreen()
  }

  render() {

    const content = this.props.content
    const imageSrc = ( content.image && content.image.localFile ) ? content.image.localFile.childImageSharp.fluid.src : null

    const classes = [
      'component',
      'component-VideoFullwidth',
    ]

    let srcs = []
    if ( content.video_mp4 ) {
      srcs.push({
        mime: content.video_mp4.mime_type,
        src: content.video_mp4.source_url
      })
    }
    if ( content.video_webm ) {
      srcs.push({
        mime: content.video_webm.mime_type,
        src: content.video_webm.source_url
      })
    }
    if ( content.video_ogg ) {
      srcs.push({
        mime: content.video_ogg.mime_type,
        src: content.video_ogg.url.source_url
      })
    }


    //Image console
    imageLoadedStatus('VideoFullwidth.js', (!!imageSrc), content.image.alt_text)

    return (
      <div className={classes.join(' ')}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

              <div className="fullwidth__inner">

                <Video 
                  ref={r => (this.v = r)}
                  absolute={true}
                  srcs={srcs}
                />

                <div 
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                  }}
                  onClick={() => this.handleVideo()}
                  onMouseEnter={() => this.p.handleHover(true)}
                  onMouseLeave={() => this.p.handleHover(false)}
                  onMouseDown={() => this.p.handleFocus(true)}
                  onMouseUp={() => this.p.handleFocus(false)}
                >
                  
                  <BackgroundHover 
                    image_url={imageSrc} 
                    hover="zoom" 
                  />
                  
                  <PlayIcon 
                    ref={r => (this.p = r)}
                    size={100}
                    child={true}
                    onClick={() => this.handleVideo()}
                   />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


//Props validation
VideoFullwidth.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default VideoFullwidth