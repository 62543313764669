import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TextLinks from './TextLinks'
import { imageLoadedStatus } from '../misc/utility.js'


//Component
class Coworker extends Component {
  
  render() {

    const props = this.props
    const single_page = ( props.single ) ? true : false
    const name = ( props.name ) ? props.name : null
    const title = ( props.title ) ? props.title : null
    const mail = ( props.mail ) ? props.mail : null
    const phone = ( props.phone ) ? props.phone : null
    const about = ( props.about ) ? props.about : null
    const vertical_image = ( props.vertical_image && props.vertical_image.localFile ) ? props.vertical_image : null
    const vertical_image_alt = ( vertical_image ) ? vertical_image.alt_text : null

    const SizeMain = ( single_page ) ? 'h1' : 'h2' 
    const SizeSub = ( single_page ) ? 'h2' : 'h3' 

    //Image console
    imageLoadedStatus('CoworkerFull.js', (!!vertical_image), vertical_image_alt)


    return (
      <div className="coworker-modal">

        {vertical_image ? (
          <div className="coworker-modal__image">
            <img src={vertical_image.localFile.childImageSharp.fixed.src} srcSet={vertical_image.localFile.childImageSharp.fixed.srcSet} alt={vertical_image_alt}/>
          </div>
        ) : null}

        <div className="coworker-modal__content">

          <SizeMain className="heading font-main size-h1-heading" dangerouslySetInnerHTML={{__html: name }}/>

          { title ? (
            <SizeSub className="title font-main size-h2-heading" dangerouslySetInnerHTML={{__html: title }}/>
          ) : null}

          { about ? (
            <div className="text-container" dangerouslySetInnerHTML={{__html: about }} />
          ) : null}
          
          { mail ? (
            <TextLinks content={mail} uppercase={true} />
          ) : null}

          { phone ? (
            <TextLinks content={phone} uppercase={true} />
          ) : null}
          
        </div>

      </div>
    )
  }
}


//Props validation
Coworker.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  mail: PropTypes.string,
  phone: PropTypes.string,
  about: PropTypes.string,
  vertical_image: PropTypes.object,
}


//Export
export default Coworker