import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LinkCTA from '../LinkCTA'
import Video from '../Video'
import FlexContainerFill from '../FlexContainerFill'
import TextLinks from '../TextLinks'

//Component
class VideoAuto extends Component {

  componentDidMount(){
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resize)
    clearTimeout(this.timer)
  }

  state = {
    videoWrapper: null,
    videoMaxHeight: '100%'
  }

  handleVideo = () => {
    this.v.play()
  }

  resize = () => {
    if ( this.state.videoWrapper && this.props.fullpage ) {
      this.setState({
        videoMaxHeight: this.state.videoWrapper.getBoundingClientRect().height
      })
    }
  }

  handlevideoWrapper = (ele) => {

    if ( ele && this.props.fullpage ) {

      this.setState({
        videoWrapper: ele,
        videoMaxHeight: ele.getBoundingClientRect().height
      }, () => {
        this.timer = setTimeout(() => {

          if ( this.state.videoWrapper ) {
            //Update the state again after a short timeout to get the correct height
            this.setState({
              videoMaxHeight: this.state.videoWrapper.getBoundingClientRect().height
            })
          }
        }, 500)
      })
    }
  }

  render() {
    const content = this.props.content
    const fullpageindex = this.props.index
    const link = content.link
    const HeadingSize = ( content.heading_size && content.heading_size !== 'h1' ) ? content.heading_size : 'h2'
    const font_family = ( content.font_family === 'RoadRage' ) ? 'font-header' : 'font-main'
    const font_size = ( content.heading_size === 'h1' ) ? ' size-h1-heading' : null

    const heading_classes = [
      font_family,
      font_size
    ]

    let srcs = []
    if ( content.video_mp4 ) {
      srcs.push({
        mime: content.video_mp4.mime_type,
        src: content.video_mp4.source_url
      })
    }
    if ( content.video_webm ) {
      srcs.push({
        mime: content.video_webm.mime_type,
        src: content.video_webm.source_url
      })
    }
    if ( content.video_ogg ) {
      srcs.push({
        mime: content.video_ogg.mime_type,
        src: content.video_ogg.url.source_url
      })
    }

    return (
      <div className="component component-VideoAuto">

        <FlexContainerFill>
          
          <VideoWrapper ref={this.handlevideoWrapper} className="video-wrapper video-border">
            <Video 
              ref={r => (this.v = r)}
              fullpageindex={fullpageindex}
              srcs={srcs}
              autoPlay
              muted
              loop
              playsInline
              border={content.border}
              style={{
                maxHeight: this.state.videoMaxHeight,
                maxWidth: '100%'
              }}
            />
          </VideoWrapper>


          <div className="content-container row">

            <div className="content col-12 col-md-8 col-xl-6">
              {(content.heading) ? ( 
                <HeadingSize className={heading_classes.join(' ')} dangerouslySetInnerHTML={{__html: content.heading }} />
              ) : null }

              {(content.text) ? ( 
                <TextLinks content={content.text}/> 
              ) : null }
            </div>

            {(link) ? ( 
              <div className="cta col-12 col-md-4 col-xl-6 mt-2 mt-md-0 d-flex justify-content-center justify-content-md-end align-items-md-end">
                <LinkCTA 
                  to={link.url} 
                  target={link.target} 
                  title={link.title}
                  background={true}
                />
              </div>          
            ) : null}

          </div>
          
        </FlexContainerFill>
      </div>
    )
  }
}


//Props validation
VideoAuto.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default VideoAuto


//Styles
const VideoWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`