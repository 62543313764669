import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ImageHover from './ImageHover'
import { setServer, getServer } from '../misc/utility.js'

//Component
class LightboxImage extends Component {

  componentDidMount = () => {

    //Add image information to _server lightboxGalleries and save
    let _server = getServer()
    const galleryID = this.props.galleryID
    const imageID = ( this.props.fluid ) ? this.props.fluid.originalImg : null
    const thisImage = {
      fluid: this.props.fluid,
      altText: this.props.altText
    }

    _server.lightboxGalleries = _server.lightboxGalleries || []
    _server.lightboxGalleries[galleryID] = _server.lightboxGalleries[galleryID] || []
    _server.lightboxGalleries[galleryID][imageID] = thisImage

    setServer( _server )
  }

  componentWillUnmount = () => {

    //unmountGallery can be set to false, in this case a parent component needs to handle the gallery
    //unmountGallery needs to be false for filtering av lightbox images to work properly
    if ( this.props.unmountGallery === undefined  || this.props.unmountGallery !== false ) { 

      //Remove image information to _server lightboxGalleries and save
      let _server = getServer()
      const galleryID = this.props.galleryID
      const imageID = ( this.props.fluid ) ? this.props.fluid.originalImg : null

      if ( _server.lightboxGalleries[galleryID] && _server.lightboxGalleries[galleryID][imageID] ) {
        delete _server.lightboxGalleries[galleryID][imageID]
      }

      setServer( _server )
    }
  }

  handleClick = (fluid, e) => {
    e.preventDefault()

    let _server = getServer()
    const imageID = ( this.props.fluid ) ? this.props.fluid.originalImg : null

    if ( _server.lightboxModal ) {

      _server.lightboxModal.openModal({
        type:         'gallery',
        imageID:      imageID,
        galleryID:    this.props.galleryID
      })
    }
  }

  render() {

    const { fluid, altText, className } = this.props
    const fluidSrc = ( fluid && fluid.src ) ? fluid.src : null
    const fluidRatio = ( fluid ) ? fluid.aspectRatio : null

    return (  
      <a href={fluidSrc} onClick={(e) => this.handleClick(fluid, e)}>

        <ImageHover
          hoverStyle="zoom" 
          fluid={fluid} 
          alt={altText} 
          darkFilter={false}
          style={{
            position: 'absolute',
            top: 0,
            width: '100%', 
            height: 0,
            paddingBottom: 100/fluidRatio+'%'
          }}
          imgStyle={{
            width: '100%',
            height: '100%',
          }}
          className={className}
        />

      </a>
    )
  }
}


//Props validation
LightboxImage.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.bool
}


//Export
export default LightboxImage