import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LinkDynamic from './LinkDynamic'
import BackgroundHover from './BackgroundHover'


//Component
class FullwidthItem extends Component {

  state = {
    hover: false
  }

  handleHover = (hover) => this.setState(
    { 
      hover: hover 
    }
  )

  render() {

    let {title, label, link, image_url, className, align_bg_x, align_bg_y, ...other} = this.props

    let containerClasses = [
      'fullwidth-item',
      ( className ) ? className : null
    ]

    let labelClasses = [
      'size-h1-heading',
      'font-main'
    ]

    let backgroundClassName = ( this.state.hover ) ? 'trigger-hover' : null 

    return (
    	<LinkDynamic 
        to={link} 
        className={containerClasses.join(' ')}
        onMouseEnter={() => this.handleHover(true)}
        onMouseLeave={() => this.handleHover(false)}
        {...other}
      >

        <div className="background-container">
          <BackgroundHover 
            image_url={image_url} 
            hover="right"
            className={backgroundClassName}
            align_bg_x={align_bg_x}
            align_bg_y={align_bg_y}
          />
        </div>

        <div className="content">

          {( label ) ? (
            <h3 className={labelClasses.join(' ')} dangerouslySetInnerHTML={{__html: label }} />
          ) : null }

          {( title ) ? (
            <h2 className="size-h1-sub-heading font-main" dangerouslySetInnerHTML={{__html: title }} />
          ) : null }

        </div>

      </LinkDynamic>
    )
  }
}


//Props validation
FullwidthItem.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default FullwidthItem