import React from 'react'
import PropTypes from 'prop-types'
import LightboxImage from '../LightboxImage'
import Text from '../Text'
import TextLinks from '../TextLinks'
import LinkCTA from '../LinkCTA'

//import ReactFullpage from '@fullpage/react-fullpage/dist/react-fullpage-commonjs'
//import 'react-fancybox/lib/fancybox.css'


//Component
const TextImage = ( props ) => {

  const { heading_size, text, title, image, image_alignment, font_family, link } = props.content

  let classes = [
  	'component',
  	'component-TextImage',
  	'image-'+image_alignment
  ]

  function heading() {

    const HeadingSize = ( heading_size === 'h1' ) ? 'h2': heading_size;

    let heading_classes = [
    	'main-heading',
      'mb-2',
      'text-left',
      'color-purple-action'
    ]

    if ( font_family === 'RoadRage' ) {
      heading_classes.push('font-header')
    } else {
      heading_classes.push('font-main')
    }

    if ( heading_size === 'h1' ) {
      heading_classes.push('size-h1-heading')
    }

    if ( title ) {
      return <HeadingSize className={heading_classes.join(' ')} dangerouslySetInnerHTML={{__html: title }} />

    } else {
      return null

    }
  }

  const testedImage = ( image && image.localFile ) ? image.localFile.childImageSharp.fluid : null

  return (
    <div className={classes.join(' ')}>
      <div className="container-fluid">
      	<div className="row">

      		<div className="image-col col-12 col-md-6">
      			<div className="image-col__inner">
      			 
              { testedImage ? (
                <LightboxImage 
                  galleryID = {'gallery-'+props.index}
                  fluid = {testedImage}
                  altText = {image.alt_text}
                />
              ) : null}

      			</div>
      		</div>

      		<div className="content-col col-12 col-md-6 text-center">

    				<Text
              className="text-left"
            >
    					{heading()}
      				
              {text ? (
                <TextLinks content={text} />
              ) : null}

              {link ? (
                <LinkCTA 
                  title = {link.title}
                  to = {link.url}
                  target = {link.target}
                  className = "mt-1"
                  background = {true}
                />
              ) : null}
      			</Text>

      		</div>

      	</div>
    	</div>
    </div>
  )
}


//Props validation
TextImage.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default TextImage