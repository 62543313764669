import React from 'react'
import PropTypes from 'prop-types'
import ComponentImageWrapper from '../ComponentImageWrapper'
import LinkCTA from '../LinkCTA'
import Text from '../Text'
import TextLinks from '../TextLinks'
import { imageLoadedStatus } from '../../misc/utility.js'


//Component
const ImageIntro = ( props ) => {

  const content = props.content

  const align_bg_x = content.align_bg_x
  const align_bg_y = content.align_bg_y
  const image_url = ( content.main_image && content.main_image.localFile ) ? content.main_image.localFile.childImageSharp.fluid.src : null
  const HeadingSize = ( content.heading_size === 'h1-epic' ) ? 'h1' : content.heading_size

  function heading() {

    let heading_classes = []

    if ( content.font_family === 'RoadRage' ) {
      heading_classes.push('font-header')
    } else {
      heading_classes.push('font-main')
    }

    if ( content.heading_color === 'purple' ) {
      heading_classes.push('color-purple-action')
    }

    if ( content.heading_size === 'h1-epic' ) {
      heading_classes.push('size-epic-heading')
    }

    if ( content.heading ) {
      return <HeadingSize className={heading_classes.join(' ')} dangerouslySetInnerHTML={{__html: content.heading }} />

    } else {
      return null

    }
  }

  //Image console
  imageLoadedStatus('ImageIntro.js', (!!image_url), content.main_image.alt_text )

  return (
    <ComponentImageWrapper
      component = "ImageIntro"
      image_url = {image_url}
      align_bg_x = {align_bg_x}
      align_bg_y = {align_bg_y}
    >
      <div className="container-fluid text-center align-items-center">

        {(content.heading || content.excerpt) ? (
          ( content.heading_size === 'h1-epic' ) ? (

            <React.Fragment>
              {heading()}

              {(content.excerpt) ? (
                <Text>
                  <TextLinks className="size-lg-text" content={content.excerpt} />
                </Text>
              ) : null}

            </React.Fragment>

          ) : (

            <Text>
              {heading()}
                <TextLinks className="size-lg-text" content={content.excerpt} />
            </Text>

          )
        ) : null}


        {( content.link ) ? (
          <LinkCTA 
            to={content.link.url}
            title={content.link.title}
            target={(content.link.target) ? "_blank" : null}
          />
        ) : null}

        {(content.scroll_text) ? (
          <p className="text-prompt" dangerouslySetInnerHTML={{__html: content.scroll_text }} />
        ) : null}

      </div>
    </ComponentImageWrapper>
  )
}


//Props validation
ImageIntro.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default ImageIntro