import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LinkCTA from '../LinkCTA'
import Video from '../Video'
import BackgroundHover from '../BackgroundHover'
import PlayIcon from '../PlayIcon'
import FlexContainerFill from '../FlexContainerFill'
import FlexFillInner from '../FlexFillInner'
import TextLinks from '../TextLinks'
import { imageLoadedStatus } from '../../misc/utility.js'

//Component
class VideoSection extends Component {

  handleVideo = () => {
    this.v.playFullscreen()
  }

  render() {
    const content = this.props.content
    const link = content.link
    const HeadingSize = ( content.heading_size && content.heading_size !== 'h1' ) ? content.heading_size : 'h2'
    const font_family = ( content.font_family === 'RoadRage' ) ? 'font-header' : 'font-main'
    const font_size = ( content.heading_size === 'h1' ) ? ' size-h1-heading' : null
    const image_src = ( content.image && content.image.localFile ) ? content.image.localFile.childImageSharp.fluid.src : null

    const heading_classes = [
      font_family,
      font_size
    ]

    let srcs = []
    if ( content.video_mp4 ) {
      srcs.push({
        mime: content.video_mp4.mime_type,
        src: content.video_mp4.source_url
      })
    }
    if ( content.video_webm ) {
      srcs.push({
        mime: content.video_webm.mime_type,
        src: content.video_webm.source_url
      })
    }
    if ( content.video_ogg ) {
      srcs.push({
        mime: content.video_ogg.mime_type,
        src: content.video_ogg.url.source_url
      })
    }

    //Image console
    imageLoadedStatus('VideoSection.js', (!!image_src), content.image.alt)

    return (
      <div className="component component-VideoSection">

        <Video 
          ref={r => (this.v = r)}
          absolute={true}
          srcs={srcs}
        />

        <FlexContainerFill>

          <FlexFillInner className="d-flex justify-content-center align-items-center">
            <span 
              onClick={() => this.handleVideo()}
              onMouseEnter={() => this.p.handleHover(true)}
              onMouseLeave={() => this.p.handleHover(false)}
              onMouseDown={() => this.p.handleFocus(true)}
              onMouseUp={() => this.p.handleFocus(false)}
            >

              <BackgroundHover 
                image_url={image_src} 
                hover="right" 
              />

              <PlayIcon 
                ref={r => (this.p = r)}
                size={100}
                child={true}
                onClick={() => this.handleVideo()}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              />

            </span>
          </FlexFillInner>

          <div className="content-container row">

            <div className="content col-12 col-md-8 col-xl-6">
              {(content.heading) ? ( 
                <HeadingSize className={heading_classes.join(' ')} dangerouslySetInnerHTML={{__html: content.heading }} />
              ) : null }

              {(content.text) ? ( 
                <TextLinks content={content.text}/> 
              ) : null }
            </div>

            {(link) ? ( 
              <div className="cta col-12 col-md-4 col-xl-6 mt-2 mt-md-0 d-flex justify-content-center justify-content-md-end align-items-md-end">
                <LinkCTA to={link.url} target={link.target} title={link.title} />
              </div>          
            ) : null}

          </div>
          
        </FlexContainerFill>
      </div>
    )
  }
}


//Props validation
VideoSection.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default VideoSection