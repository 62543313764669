import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { DefaultPlayer/*, apiHelpers*/ } from 'react-html5video'
import 'react-html5video/dist/styles.css'


//Component
class Video extends Component {

	componentDidMount() {
    if ( typeof window !== 'undefined' ) {
      window.addEventListener('webkitfullscreenchange', this.exitFullscreenHandler, false)
      window.addEventListener('mozfullscreenchange', this.exitFullscreenHandler, false)
      window.addEventListener('fullscreenchange', this.exitFullscreenHandler, false)
      window.addEventListener('MSFullscreenChange', this.exitFullscreenHandler, false)
      //window.addEventListener('scroll', this.handleScroll)
      //window.addEventListener('scrollbar', this.handleScrollbar)
      window.addEventListener('fullpagescroll', this.handleFullpage)
    }
  }

  componentWillUnmount() {
    if ( typeof window !== 'undefined' ) {
      window.removeEventListener('webkitfullscreenchange', this.exitFullscreenHandler)
      window.removeEventListener('mozfullscreenchange', this.exitFullscreenHandler)
      window.removeEventListener('fullscreenchange', this.exitFullscreenHandler)
      window.removeEventListener('MSFullscreenChange', this.exitFullscreenHandler)
      //window.removeEventListener('scroll', this.handleScroll)
      //window.removeEventListener('scrollbar', this.handleScrollbar)
      window.removeEventListener('fullpagescroll', this.handleFullpage)
    }
  }

  play = () => {
    this.player.videoEl.play()
  }

  pause = () => {
    this.player.videoEl.pause()
  }

	playFullscreen = () => {
    const fullscreenElement = document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement
    const videoEl = this.player.videoEl
    videoEl.requestFullScreen = videoEl.requestFullscreen || videoEl.msRequestFullscreen || videoEl.mozRequestFullScreen || videoEl.webkitRequestFullscreen

    if (fullscreenElement !== videoEl) {
      videoEl.requestFullScreen()
      this.play()
    }
  }

  exitFullscreenHandler = () => {
    if ( typeof document !== 'undefined' ) {
    	const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement

    	if ( !fullscreenElement ) {
  	  	this.pause()
      }
    }
  }

  handleScroll = (e) => {
    if ( typeof window !== 'undefined' && this.player && this.props.autoPlay ) {
      
      const offset = ( window.pageYOffset ) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
      
      this.isInViewport({
        offset: offset
      })
    }
  }

  handleScrollbar = (e) => {
    if ( this.player && this.props.autoPlay ) {
      
      const offset = e.top
      
      this.isInViewport({
        offset: offset
      })
    }
  }

  handleFullpage = (e) => {
    if ( typeof window !== 'undefined' && this.player && this.props.autoPlay && window.matchMedia("(min-width: 768px)").matches ) {
      this.isInViewport({
        destination: e.destination.index
      })
    }
  }

  isInViewport = ( args ) => {
    if ( this.player && this.props.autoPlay ) {

      if ( 'offset' in args ) {

        const boundingClientRect = this.player.videoEl.getBoundingClientRect()
        const eleTop = boundingClientRect.top
        const eleHeight = boundingClientRect.height
        const winHeight = ( typeof window !== 'undefined' ) ? window.innerHeight  : 0
        const eleInViewport = ((eleTop < winHeight) && (eleTop+eleHeight >= 0))

        if ( eleInViewport && this.player.videoEl.paused ) {
          this.play()

        } else {
          this.pause()

        }

      } else if ( 'destination' in args ) {

        if ( args.destination === this.props.fullpageindex ) {
          this.play()

        } else {
          this.pause()
        }

      } else {
        this.pause()

      }
    }
  }

	render () {

		const {srcs, thumbnail, absolute, border, ...other} = this.props

		const container_classes = [
			'video',
      ( 'autoPlay' in other  ) ? 'autoplay' : '',
			( absolute ) ? 'absolute' : '',
      ( border ) ? 'video-border' : ''
		]
		const controls = ( !('autoPlay' in other) ) ? ['PlayPause', 'Time', 'Seek', 'Volume', 'Fullscreen'] : null
    const poster = ( thumbnail ) ? thumbnail : null

		return (
			<StaticQuery

        query={graphql`
          query statucVideoQuery {
            site {
              siteMetadata {
                wordpressUrl
              }
            }
          }
        `}

        render = {data => {
          
          const wordpressUrl = data.site.siteMetadata.wordpressUrl

          return (
            <div className={container_classes.join(' ')}>
              <DefaultPlayer 
                ref={r => (this.player = r)}
                controls={controls}
                poster={poster}
                {...other}
              >

                {srcs.map( (node , index) => {

                  let src = (node.src.startsWith("/wp-content")) ? wordpressUrl+node.src.replace("/w", "w") : node.src

                  return (
                    <source key={index} src={src} type={node.mime} />
                  )
                })}

              </DefaultPlayer>
            </div>
          )
        }}
      />
  	)
	}
}


//Props validation
Video.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Video