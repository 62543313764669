import React from 'react'
import PropTypes from 'prop-types'


//Component
const ComponentImageWrapper = ({ component, children, dark_filter, image_url, align_bg_x, align_bg_y, className }) => {

  const classes = [
    'component',
    'component-ImageWrapper',
    'component-'+component,
    ( className ) ? className : null,
    ( dark_filter !== false ) ? 'dark-overlay' : null,
  ]

  const align_x = ( align_bg_x ) ? align_bg_x : 'center'
  const align_y = ( align_bg_y ) ? align_bg_y : 'top'

  const containerStyle = (image_url) ? {
    backgroundImage:    `url(${image_url})`,
    backgroundPosition: `${align_y} ${align_x}`,
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'cover',
  } : null

  return (
    <div className={classes.join(' ')} style={containerStyle}>
      
      {children}

    </div>
  )
}


//Props validation
ComponentImageWrapper.propTypes = {
  //children: PropTypes.array,
  //children: PropTypes.object,
  dark_filter: PropTypes.bool,
  image_url: PropTypes.string,
  className: PropTypes.string,
}


//Export
export default ComponentImageWrapper