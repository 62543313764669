import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Coworker from '../Coworker'
import MenuCategory from '../MenuCategory'
import Pagination from '../Pagination'
import { EasingFunctions } from '../../misc/easings.js'


//Component
class Coworkers extends Component {

  constructor(props) {
    super(props);
    this.DOMNode = React.createRef();
  }


  state = {
    search: '',
    currentCat: 'all',
    currentPage: 1,
    paginatedCoworkers: [],
    winWidth: 0,
    maxHeight: 2000,

    //Extra padding for name under image
    namePadding: 90,

    // min-width : total padding
    containerPaddings: {
      0    : 15*2,
      768  : 60*2,
      1200 : 80*2
    },

    // min-width : max rows
    sizes : {
      0     : 3,
      600   : 6,
      900   : 9,
      1200  : 40,
      1500  : 50,
      1800  : 60,
      2100  : 70,
      2400  : 80,
      2700  : 90,
      3000  : 100
    },
    pos: {
      x: undefined,
      y: undefined
    },
  }


  componentDidMount() {
    if ( typeof window !== 'undefined' ) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }


  componentWillUnmount() {
    if ( typeof window !== 'undefined' ) {
      clearTimeout(this.timeout)
      window.removeEventListener('resize', this.updateWindowDimensions)
    }
  }


  updateWindowDimensions = () => {
    if ( typeof window !== 'undefined' ) {
      this.setState({
        winWidth: window.innerWidth,
        currentPage: 1
      }, function() {
        this.updateCoworkerRow()
      })
    }
  }


  handleSearch = ( e ) => {
    e.preventDefault()

    this.setState({
      search: e.target.value,
      currentPage: 1
    }, function() {
      this.updateCoworkerRow()
    })
  }


  handleCategory = ( cat ) => {
    this.setState({
      currentCat: cat,
      currentPage: 1
    }, function() {
      this.updateCoworkerRow()
    })
  }


  handlePagination = (i) => {
    this.setState({
      currentPage: i
    }, function() {
      this.updateCoworkerRow()
      this.scrollTo()
    })
  }


  containsObjectSlug = (objSlug, list) => {
    let i

    for (i = 0; i < list.length; i++) {
        if (list[i].slug === objSlug) {
            return true
        }
    }

    return false
  }


  synonyms = (search, name) => {

    /*
     * looseSynonyms matches search with looseSynonyms.key for every keyUp
     */
    const looseSynonyms = {
      'Victor'        : 'Viktor',
      'Viktor'        : 'Victor',
      'Karoline'      : 'Caroline',
      'Karro'         : 'Caroline',
      'Carro'         : 'Caroline',
      'Tomas'         : 'Thomas',
      'Coffe'         : 'Christopher',
      'Christoffer'   : 'Christopher',
      'Christofer'    : 'Christopher',
      'Kristoffer'    : 'Christopher',
      'Kristofer'     : 'Christopher',
      'Carl'          : 'Karl',
      'Karl'          : 'Carl',
      'Per-Olov'      : 'Per Olov',
      'Pär Olof'      : 'Per Olov',
      'Pär Olov'      : 'Per Olov',
      'Per Olof'      : 'Per Olov',
      'Peppe'         : 'Per Olov',
      'PO'            : 'Per Olov',
      'Marcus'        : 'Markus',
      'Markus'        : 'Marcus',
      'Jossan'        : 'Josephine',
      'Josefin'       : 'Josephine',
      'Josefine'      : 'Josephine',
      'Kristian'      : 'Christian',
    }

    const looseEntries = Object.entries(looseSynonyms)
    for ( let i = 0; i < looseEntries.length; i++ ) {

      let synonym = looseEntries[i][0].toLowerCase()
      let definition = looseEntries[i][1].toLowerCase()

      if ( (name.indexOf(definition) !== -1) && (synonym.indexOf(search) !== -1) ) {
        return true
      }
    }

    /*
     * exactSynonyms requires exactSynonyms.key to exactly match the search
     */
    const exactSynonyms = {
      'Tönt'        : 'Benjamin',
      'Bajskorven'  : 'Josef'
    }

    const exactEntries = Object.entries(exactSynonyms)
    for ( let i = 0; i < exactEntries.length; i++ ) {

      let synonym = exactEntries[i][0].toLowerCase()
      let definition = exactEntries[i][1].toLowerCase()

      if ( (name.indexOf(definition) !== -1) && synonym === search ) {
        return true
      }
    }

    return false
  }


  updateCoworkerRow = ( scroll ) => {

    scroll = scroll || false

    const filteredCoworkers = this.filterCoworkers( this.props.content.coworkers )
    const paginatedCoworkers = this.paginateCoworkers( filteredCoworkers )
    const maxHeight = this.calculateHeight( paginatedCoworkers )

    this.setState({
      paginatedCoworkers: paginatedCoworkers,
      maxHeight: maxHeight
    })
  }


  filterCoworkers = (coworkers) => {
    /*
     * Filter coworkers depening on search and current category
     */
    if ( !coworkers ){
      return false
    }

    return coworkers.filter((coworker) => {

      const search = this.state.search.toLowerCase()
      const name = coworker.post_title.toLowerCase()
      const label = coworker.acf.label.toLowerCase()
      const mail = coworker.acf.mail.toLowerCase()

      //Filter by search
      if ( (name.indexOf(search) !== -1) || (label.indexOf(search) !== -1) || (mail.indexOf(search) !== -1) || this.synonyms(search, name) ) {

        //Filter by Category
        const currentCat = this.state.currentCat
        const categories = coworker.acf.cpt_categories

        if ( currentCat === 'all' ) {
          return true

        } else {
          return this.containsObjectSlug( currentCat, categories )

        }

      } else {
        return false

      }
    })
  }


  paginateCoworkers = (coworkers) => {

    if ( !coworkers ){
      return false
    }

    const { sizes, winWidth } = this.state
    const entries = Object.entries(sizes).reverse()

    let paginatedCoworkers = []

    /*
     * Split coworkers array into smaller chunks used to pages
     */
    for ( let i = 0; i < entries.length; i++ ) {

      let size = entries[i][0]
      let per_page = entries[i][1]

      if ( winWidth > size || winWidth === 0 ) {

        while (coworkers.length > 0) {
          paginatedCoworkers.push( coworkers.splice(0, per_page) );
        }

        break
      }
    }

    /*
     * Set state for paginatedCoworkers
     */
    return paginatedCoworkers
  }


  calculateHeight = (paginatedCoworkers) => {
    
    if ( !paginatedCoworkers ){
      return false
    }

    //Get variables from state
    const {currentPage, winWidth, containerPaddings, namePadding, sizes} = this.state

    //Standard values
    let per_page = 100
    let containerPadding = 80*2

    //Max number of rows per page
    const maxRows = ( typeof window !== 'undefined' && window.matchMedia("(max-width: 1199px)").matches ) ? 3 : 10

    //Itterate through sizes to find how many coworkers that 
    //should be displayed per page for the curretn screen size
    const sizeEntries = Object.entries(sizes).reverse()

    if ( sizeEntries ) {
      for ( let i = 0; i < sizeEntries.length; i++ ) {

        const size = sizeEntries[i][0]
        per_page = sizeEntries[i][1]

        if ( winWidth >= size || winWidth === 0 ) {
          break
        }
      }
    }

    //Itterate thorught containerPaddings and find current padding
    const paddingEntries = Object.entries(containerPaddings).reverse()

    if ( paddingEntries ) {
      for ( let i = 0; i < paddingEntries.length; i++ ) {

        const size = paddingEntries[i][0]
        containerPadding = paddingEntries[i][1]

        if ( winWidth >= size || winWidth === 0 ) {
          break
        }
      }
    }

    //Calculate number of columns
    const cols = per_page / maxRows;

    //Calculate actual number of rows
    const rows = ( paginatedCoworkers[currentPage-1] ) ? Math.ceil( paginatedCoworkers[currentPage-1].length / cols ) : 1

    //Calculate container inner width
    const containerWidth = winWidth - containerPadding

    //Calculate single item width (+30 is for rows negative margin)
    const itemWidth = (containerWidth + 30) / cols

    //Calculate item height ( - and + 30 is to compensate for item  )
    const itemHeight = ((itemWidth - 30) * 0.9) + namePadding + 30

    //Calculate height of whole block based on actual rows and itemHeight
    const blockHeight = Math.ceil( itemHeight * rows )

    return blockHeight
  }


  scrollTransition = (obj) => {

    const {startPos, duration, steps, distance} = obj
    const easing = EasingFunctions.easeOutQuint(obj.progress)

    //Counts itterations
    obj.itterations--
    obj.currentPos = startPos + (distance*easing)
    obj.progress = 1 - (obj.itterations/steps)

    //Sets timeout to simulate smooth scrolling
    const that = this;
    that.timeout = setTimeout(function() {  
      if (obj.itterations) {

        window.scrollbars.scrollTop(obj.currentPos);
        that.scrollTransition(obj)

      }
    }, (duration/steps))
  }


  scrollTo = () => {
    if ( typeof window !== 'undefined' ) {
      const startPos = window.scrollbars.getScrollTop()
      const distance = this.DOMNode.current.getBoundingClientRect().y
      const endPos = startPos + distance
      const duration = 1000 //MS
      const framesPerSec = 60
      const totalSec = duration/1000
      const itterations = Math.floor(totalSec*framesPerSec)

      let obj = {
        progress:     0,
        startPos:     startPos,
        currentPos:   startPos,
        endPos:       endPos,
        distance:     distance + 20,
        duration:     duration,
        framesPerSec: framesPerSec,
        totalSec:     totalSec,
        itterations:  itterations,
        steps:        itterations,
      }
      
      this.scrollTransition(obj)
    }
  }


  render() {

    const {currentCat, currentPage, search, maxHeight, paginatedCoworkers} = this.state
    const data = this.props
    const type = ( data.content.type ) ? data.content.type : 'simple'
    const category_items = data.content.coworkers_category

    let component_classes = [
      'component',
      'component-Coworkers',
      'mb-0',
      'pb-0',
      type
    ]

    let sectionMsg = currentCat !== 'all' ? ' '+currentCat.toUpperCase() : ''
    let searchMsg = search ? ' som matchar sökningen "'+search+'"' : ''
    let empty_search_message = 'Just nu finns det ingen anställd på OAS'+sectionMsg+searchMsg

    return (

      <React.Fragment>

        <div className={component_classes.join(' ')} ref={this.DOMNode}>
          <div className="container-fluid">

            { type === 'advanced' ? (
              <React.Fragment>

                <div className="row mb-2">
                  <div className="col-12">

                    <form onSubmit={(e) => { 
                      e.preventDefault()
                      if ( typeof document !== 'undefined' ) {
                        document.activeElement.blur()
                      }
                    }}>
                      <input 
                        className="search-input" 
                        type="text" 
                        placeholder="SÖK..."
                        onChange={e => this.handleSearch(e)}
                      />
                      <input 
                        type="submit" 
                        value="Submit"
                        tabIndex="-1"
                        style={{
                          visibility: 'hidden',
                          position: 'absolute',
                          left: -9999,
                          width: 1,
                          height: 1
                        }} 
                      />
                    </form>

                  </div>
                </div>

                <div className="row">
                  <div className="col-12">

                    <MenuCategory 
                      items = {category_items} 
                      onChange = {(cat) => this.handleCategory(cat)}
                      allTitle = "Alla"
                      className = "mt-0"
                    />

                  </div>
                </div>

              </React.Fragment>
            ) : null}

          </div>
        </div>

        <div className="component component-Coworkers mt-0 pt-0">
          <div className="container-fluid">

            <div className="row content-row" style={{
              maxHeight: maxHeight
            }}>

              {paginatedCoworkers[currentPage-1] && paginatedCoworkers[currentPage-1].length >= 1 ? paginatedCoworkers[currentPage-1].map( (node , index) => {
                return (

                  <Coworker 
                    key = {index} 
                    {...node} 
                  />

                )
              }) : (
                <div className="col-12" dangerouslySetInnerHTML={{__html: empty_search_message }}/>
              )}

            </div>

            { type === 'advanced' && paginatedCoworkers.length > 1 ? (
              <div className="row">
                <div className="col-12 mt-1 mt-sm-2 mt-md-3">

                  <Pagination
                    pages={paginatedCoworkers}
                    currentPage={currentPage}
                    onChange = {(i) => this.handlePagination(i)}
                  />

                </div>
              </div>
            ) : null}

          </div>
        </div>

      </React.Fragment>
    )
  }
}


//Props validation
Coworkers.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Coworkers