import React from 'react'
import PropTypes from 'prop-types'
import LightboxImage from '../LightboxImage'

//Component
const Images = ( props ) => {

  const { images } = props.content

  const imageElements = images.map(function(node, index){

    let classes = [
      'col-12',
      'col-md-'+node.width
    ]

    const image = node.image
    const fluid = ( image && image.localFile ) ? image.localFile.childImageSharp.fluid : null

     return (
          <li key={index} className={classes.join(' ')}>
            { node.image ? (
              <LightboxImage 
                galleryID = {'gallery-'+props.index}
                fluid = {fluid}
                altText = {image.alt_text}
                className = "mb-1 mb-md-2"
              />
            ) : null}
          </li>
      );
  });
  
  return (
    <div className="component component-Images">
      <div className="container-fluid">
        <ul className="row">
          
          {imageElements}

        </ul>
      </div>
    </div>
  )
}


//Props validation
Images.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Images