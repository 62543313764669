import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Masonry from 'react-masonry-component'
import MenuCategory from '../MenuCategory'
import LightboxImage from '../LightboxImage'
import VideoPlay from '../VideoPlay'
import { setServer, getServer } from '../../misc/utility.js'


//Component
class MasonryGallery extends Component {

  componentDidMount() {
    //Prepare filter categories
    if ( this.props.content && this.props.content.use_filter ) {
      if ( this.props.content.images ) {

        let categories = []

        categories = this.props.content.images.filter((node) => node.categories !== null).map((node, index) => {

          let node_categories = node.categories.split(',')
          let catObj = node_categories.map((cat, index) => {
            return {
              name: cat,
              slug: this.slugify( cat )
            }
          })

          return catObj
        })

        //Remove Duplicates from categories
        if ( categories ) {
          this.setState({
            unfilterdCatItems: categories.flat(1)
          })
        }
      }
    }
  }

  state = {
    current: (this.props.current_category) ? this.props.current_category : 'all',
    unfilterdCatItems: null
  }

  handleChange = ( cat ) => {

    this.setState({
      current: cat
    })

    //Update the current lightbox-gallery with the correct images
    let _server = getServer()

    const { images } = this.props.content
    const galleryID = 'masonry-'+this.props.index
    

    if ( _server.lightboxGalleries && _server.lightboxGalleries[galleryID] ) {

      //Empty current gallery
      _server.lightboxGalleries[galleryID] = {}

      //Filter and add correct images to gallery again
      const filterdImages = images.filter((node) => {

        //Don't show video placholders in lightbox gallery
        if ( node.type !== 'video' ) {
          
          //Filter by category
          if ( cat === 'all' ) {
            return true

          } else {
            return this.containsObjectSlug( cat, node.categories  )

          }
          
        } else {
          return false

        }
      })

      for ( let i = 0; i < filterdImages.length; i++ ) {
        const image = {
          fluid: filterdImages[i].image.localFile.childImageSharp.fluid,
          altText: filterdImages[i].image.alt_text
        }
        const imageID = filterdImages[i].image.localFile.childImageSharp.fluid.originalImg
        _server.lightboxGalleries[galleryID][imageID] = image
      }

      //Save changes
      setServer( _server )
    }

  }

  handleImagesLoaded = (imagesLoadedInstance) => { 
    
  }

  handleLayoutComplete = ( laidOutItems ) => {
    
  }

  slugify = (str) => {
    return str.toLowerCase().replace(/å|ä/,'a').replace('ö','o').replace(' ','-')
  }

  containsObjectSlug = (objSlug, list) => {
    if (list) {

      //Split comma seperated list
      list = list.split(',')

      //Loop thorugh list
      for (let i = 0; i < list.length; i++) {

        const listSlug = this.slugify( list[i] )

        if (listSlug === objSlug) {
          return true
        }
      }
    }

    return false
  }

  getUnique = (arr, comp) => {
    // Remove duplicates from array of objects
    if ( arr && comp ) {
      const unique = arr
        .map(e => e[comp])

         // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e])

      return unique
    }

    return null
  }

  render () {

    const { images, use_filter } = this.props.content
    const galleryID = 'masonry-'+this.props.index
    const filterdCatItems = this.getUnique( this.state.unfilterdCatItems, 'slug' )

    const containerClasses = [
      'component',
      'component-MasonryGallery'
    ]

    //Loop thorugh and create imageElements
    const imageElements = images.filter((node) => {

      //Filter by category
      if ( this.state.current === 'all' ) {
        return true

      } else {
        return this.containsObjectSlug( this.state.current, node.categories  )

      }

    }).map((node, index) => {

      let { width, type, image, video_webm, video_mp4, video_ogg } = node
      let classes = [
        'col-12',
        ( width === '6' ) ? 'col-xl-6' : 'col-sm-6 col-xl-3' 
      ]

      let srcs = []
      if ( type === 'video' && video_mp4 ) {
        srcs.push({
          mime: video_mp4.mime_type,
          src: video_mp4.source_url
        })
      }
      if ( type === 'video' && video_webm ) {
        srcs.push({
          mime: video_webm.mime_type,
          src: video_webm.source_url
        })
      }
      if ( type === 'video' && video_ogg ) {
        srcs.push({
          mime: video_ogg.mime_type,
          src: video_ogg.url.source_url
        })
      }

      const testedImage = ( image && image.localFile ) ? image.localFile.childImageSharp.fluid : null
      const testedImageSrc = ( testedImage ) ? testedImage.src : null
      const testedImageRatio = ( testedImage ) ? testedImage.aspectRatio : null

      return (
        <li key={index} className={classes.join(' ')}>
          { type === 'image' ? (

            <LightboxImage 
              galleryID = {galleryID}
              fluid = {testedImage}
              altText = {image.alt_text}
              unmountGallery = {false}
            />

          ) : (

            <VideoPlay 
              placeholderSrc = {testedImageSrc}
              placeholderRatio = {testedImageRatio}
              videoSrcs = {srcs}
            />

          )}
        </li>
      )
    })
    
    return (
      <div className={containerClasses.join(' ')}>

        {( use_filter && filterdCatItems ) ? (
          <div className="container-fluid">
            <MenuCategory 
              items = {filterdCatItems} 
              current = {this.state.current}
              onChange = {(cat) => this.handleChange(cat)}
              className = "mt-0"
            />
          </div>
        ) : null}

        <div className="container-fluid">
          <Masonry
            className = {'masonry row'}
            elementType = {'ul'}
            options = {{
                transitionDuration: 0
            }}
            enableResizableChildren = {true}
            disableImagesLoaded = {false}
            updateOnEachImageLoad={true}
            imagesLoadedOptions = {{ 
              background: '.background-hover--image'
            }}
            onImagesLoaded={this.handleImagesLoaded}
            onLayoutComplete={laidOutItems => this.handleLayoutComplete(laidOutItems)}
          >
              {imageElements}
          </Masonry>
        </div>
      </div>
    )
  }
}


//Props validation
MasonryGallery.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default MasonryGallery