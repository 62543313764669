import React, { Component } from 'react'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'

import { mapStyle } from '../misc/mapStyle.js'
import mapMarker from '../assets//images/marker.png'

export class MapContainer extends Component {

  componentDidMount(){
    
    let markers = this.props.markers

    this.setState({
      bounds: this.calculateBounds(markers),
      markers: markers,
      center: markers[0]
    })
  }

  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    bounds: {},
    markers: [],
    center: {}
  }

  calculateBounds = ( markers ) => {
    let bounds = {}

    if ( markers && markers.length > 1 ) {

      bounds = new this.props.google.maps.LatLngBounds()
      for (let i = 0; i < markers.length; i++) {
        bounds.extend(markers[i])
      }
    }

    return bounds
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    })
  }

  onMapReady = () => {
    this.setState({
      bounds: this.calculateBounds(this.state.markers),
      center: this.state.markers[0]
    })
  }

  render() {

    let {bounds, center, markers} = this.state

    if (!this.props.google) {
      return <div>Loading...</div>
    }

    let MapOptions = {
      google: this.props.google,
      zoom: ( this.props.zoom ) ? parseInt( this.props.zoom ) : 14,
      styles: mapStyle,
      zoomControl: true,
      fullscreenControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      panControl: false,
      rotateControl: false,
      scrollwheel: false,
      onReady: this.onMapReady,
    }

    if ( Object.keys(bounds).length > 1 ) {

      MapOptions.bounds = bounds

    } else {

      MapOptions.center = center

    }

    return (
      <div>

        <Map {...MapOptions}>

          {markers ? markers.map((node, index) => {

            const {title, lat, lng} = node

            return (
              <Marker 
                key={index}
                title={title}
                position={{
                  lat: lat,
                  lng: lng
                }}
                onClick={this.onMarkerClick}
                icon={{
                  url: mapMarker,
                  size: new this.props.google.maps.Size(38, 51),
                  scaledSize: new this.props.google.maps.Size(38, 51)
                }}
              />
            )
          }) : null}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div className="info-window">
              {this.state.selectedPlace.title}
            </div>
          </InfoWindow>

        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCW4QqJpulINgrU-J1xtde6UYPCtVEWbNY",
  v: "3.30",
  language: "sv",
})(MapContainer);