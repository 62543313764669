import React from 'react'
import PropTypes from 'prop-types'


//Component
const FlexContainerFill = ( props ) => {

  const {className, children} = props

  return (
    <div className={['flex-container-fill', className].join(' ')}>
      {children}
    </div>
  )
}


//Props validation
FlexContainerFill.propTypes = {
  className: PropTypes.string
}


//Export
export default FlexContainerFill