import React, {Component} from 'react'
//import PropTypes from 'prop-types'

//Component
class Pagination extends Component {

  handleClick = ( i ) => {

    let pages = this.props.pages ? this.props.pages : []

    i = i>=pages.length ? pages.length : i
    i = i<=1 ? 1 : i

    this.props.onChange( i )
  }

  render() {
    
    let {className, pages, currentPage} = this.props

    pages = pages ? pages : []

    let classes = [
      'nav',
      'text-center',
      'pagination-nav'
    ]

    if (className) {
      classes.push(className)
    }

    return (
      <nav className={classes.join(' ')}>
        <ul className="pagination">

          <li className={"item prev"+(currentPage<=1?' disabled':'')} onClick={() => this.handleClick(currentPage-1)}></li>
          
          {pages ? pages.map( (node , index) => {

            let item_classes = [
              'item'
            ]

            if (currentPage===index+1) {
              item_classes.push('current')
            }

            return (
              <li key={index} className={item_classes.join(' ')} onClick={() => this.handleClick(index+1)}>{index+1}</li>
            )
          }) : null }
          
          <li className={"item next"+(currentPage>=pages.length?' disabled':'')} onClick={() => this.handleClick(currentPage+1)}></li>

        </ul>
      </nav>
    )
  }
}


//Props validation
/*Layout.propTypes = {
  children: PropTypes.node.isRequired,
}*/



//Export
export default Pagination