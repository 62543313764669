import React from 'react'
import PropTypes from 'prop-types'

import AnimationIntro from './flexible/AnimationIntro'
import HeadingCta from './flexible/HeadingCta'
import ExtendedCta from './flexible/ExtendedCta'
import HeadingGroupsCta from './flexible/HeadingGroupsCta'
import Articles from './flexible/Articles'
import VideoIntro from './flexible/VideoIntro'
import VideoSection from './flexible/VideoSection'
import VideoAuto from './flexible/VideoAuto'
import VideoFullwidth from './flexible/VideoFullwidth'
import CaseSlider from './flexible/CaseSlider'
import ImageIntro from './flexible/ImageIntro'
import Images from './flexible/Images'
import MasonryGallery from './flexible/MasonryGallery'
import TextBlock from './flexible/TextBlock'
import TextImage from './flexible/TextImage'
import Textboxes from './flexible/Textboxes'
import Coworkers from './flexible/Coworkers'
import Maps from './flexible/Maps'


//Component
const FlexibleContent = ( props ) => {

  const { content } = props
  const prop_key = ( props.fullpage ) ? 'data-anchor' : 'id'

  return (
    <React.Fragment>

      {content ? ( content.map( (node , index) => {

        let section_id = node.section_id

        if ( section_id ) {
          section_id = section_id.toLowerCase()

          const replaceChars = { 
            'å':'a', 
            'ä':'a', 
            'ö':'o', 
            ' ':'-', 
            '/':'-', 
            '–':'-', 
            '_':'-', 
            '.':'-', 
            ',':'-', 
            "'":'', 
            '"':'', 
            '`':'', 
            '<':'', 
            '>':'', 
            '--':'-',
            '?':'',
            '!':'',
          }

          section_id = section_id.replace(/[åäö/ –_.,'"`<>?!]/g,function(match) {
            return replaceChars[match]
          })
        }

        let dynamicProp = {}
        dynamicProp[prop_key] = section_id

        node.__typename = node.__typename.replace('WordPressAcf_','')
        const componentName = node.__typename

        let classes = [
          'section',
          'block-' + componentName
        ]

        if ( index === 0 ) {
          classes.push('section--first')
        }

        const fixedHeightMobile = [
          'AnimationIntro',
        ]

        if ( fixedHeightMobile.indexOf( componentName ) === -1 ) {
          classes.push('fp-auto-height-responsive')
        }

        let Component = null
        let componentProps = {
          index:    index,
          fullpage: props.fullpage,
          componentName:  componentName,
          content:  node
        }

        if ( componentName === 'AnimationIntro' ) {
          Component = <AnimationIntro  {...componentProps} />

        } else if ( componentName === 'HeadingCta' ) {
          Component = <HeadingCta  {...componentProps} />

        } else if ( componentName === 'ExtendedCta' ) {
          Component = <ExtendedCta  {...componentProps} />

        } else if ( componentName === 'HeadingGroupsCta' ) {
          Component = <HeadingGroupsCta  {...componentProps} />

        } else if ( componentName === 'Press' || componentName === 'Inspo' ) {
          Component = <Articles {...componentProps} />

        } else if ( componentName === 'CaseSlider' ) {
          Component = <CaseSlider {...componentProps} />

        } else if ( componentName === 'VideoIntro' ) {
          Component = <VideoIntro {...componentProps} />

        } else if ( componentName === 'Video' ) {
          Component = <VideoSection {...componentProps} />

        } else if ( componentName === 'VideoAuto' ) {
          Component = <VideoAuto {...componentProps} />

        } else if ( componentName === 'VideoFullwidth' ) {
          Component = <VideoFullwidth {...componentProps} />

        } else if ( componentName === 'ImageIntro' ) {
          Component = <ImageIntro {...componentProps} />

        } else if ( componentName === 'Images' ) {
          Component = <Images {...componentProps} />

        } else if ( componentName === 'MasonryGallery' ) {
          Component = <MasonryGallery {...componentProps} />

        } else if ( componentName === 'Text' ) {
          Component = <TextBlock {...componentProps} />

        } else if ( componentName === 'Textboxes' ) {
          Component = <Textboxes {...componentProps} />

        } else if ( componentName === 'TextImage' ) {
          Component = <TextImage {...componentProps} />

        } else if ( componentName === 'Coworkers' ) {
          Component = <Coworkers {...componentProps} />

        } else if ( componentName === 'Maps' ) {
          Component = <Maps {...componentProps} />
          
        }

        return (

          <section key={index} className={classes.join(' ')} {...dynamicProp}>
            
            {Component}

          </section>

        )

      })) : null }

    </React.Fragment>
  )
}


//Props validation
FlexibleContent.propTypes = {
  content: PropTypes.array,
}


//Export
export default FlexibleContent