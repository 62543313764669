import React from 'react'
import PropTypes from 'prop-types'
import ImageHover from './ImageHover'
import LinkDynamic from './LinkDynamic'
import TextLinks from './TextLinks'


//Component
const Article = ( props ) => {

  const acf = props.acf
  const title = ( props.acf.heading ) ? props.acf.heading : props.post_title
  const fixed = ( acf.main_image && acf.main_image.localFile ) ? acf.main_image.localFile.childImageSharp.fixed : null
  const alt = acf.main_image.alt_text

  return (
    <div className="article-col col-12 col-md-4">
      <div className="article">

        <LinkDynamic to={props.link}>
          <ImageHover
            hoverStyle="right"
            fixed={fixed}
            alt={alt} 
            darkFilter={false}
            style={{
              position: 'absolute',
              top: 0,
              width: '100%', 
              height: 0, 
              paddingBottom: '57%'
            }}
            imgStyle={{
              width: '100%', 
              height: '100%'
            }}
            className="mb-2"
          />

          <h3 className="heading size-h2-heading font-main">{title}</h3>
          
          <TextLinks content={props.acf.excerpt} />
      
        </LinkDynamic>
      </div>
    </div>
  )
}


//Props validation
Article.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Article