import React from 'react'
import PropTypes from 'prop-types'


//Component
const FlexFillInner = ( props ) => {

  const {className, children, style} = props

  return (
    <div 
    	className={['flex-fill-inner', className].join(' ')}
    	style={style}
    >
      {children}
    </div>
  )
}


//Props validation
FlexFillInner.propTypes = {
  className: PropTypes.string
}


//Export
export default FlexFillInner