import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ComponentImageWrapper from '../ComponentImageWrapper'
import Text from '../Text'
import TextLinks from '../TextLinks'
import Video from '../Video'
import PlayIcon from '../PlayIcon'
import { imageLoadedStatus } from '../../misc/utility.js'


//Component
class VideoIntro extends Component {

  handleVideo = () => {
    this.v.playFullscreen()
  }

  render() {

    const content = this.props.content
    const {font_family, heading_color, heading, text, image} = content

    const HeadingSize = content.heading_size 
    const image_url = ( image && image.localFile ) ? image.localFile.childImageSharp.fluid.src : null

    const align_bg_x = content.align_bg_x
    const align_bg_y = content.align_bg_y

    const heading_classes = [
      'heading',
      'size-epic-heading',
      ( font_family === 'RoadRage' ) ? 'font-header' : 'font-main', 
      ( heading_color === 'purple' ) ? 'color-purple-action' : 'color-white', 
    ]

    let srcs = []
    if ( content.video_mp4 ) {
      srcs.push({
        mime: content.video_mp4.mime_type,
        src: content.video_mp4.source_url
      })
    }
    if ( content.video_webm ) {
      srcs.push({
        mime: content.video_webm.mime_type,
        src: content.video_webm.source_url
      })
    }
    if ( content.video_ogg ) {
      srcs.push({
        mime: content.video_ogg.mime_type,
        src: content.video_ogg.url.source_url
      })
    }

    //Image console
    imageLoadedStatus('VideoIntro.js', (!!image_url), image.alt_text)

    return (
      <ComponentImageWrapper
        component = "VideoIntro"
        image_url = {image_url}
        align_bg_x = {align_bg_x}
        align_bg_y = {align_bg_y}
      >

       <Video 
          ref={r => (this.v = r)}
          absolute={true}
          srcs={srcs}
        />

        <div className="container-fluid">
          <div className="row">

            <div className="col-12 col-md flex-md-shrink-1">

              {(HeadingSize && heading) ? (
                <HeadingSize className={heading_classes.join(' ')} dangerouslySetInnerHTML={{__html: heading }}/>
              ) : null}

              {(text) ? (
                <Text
                  className="size-lg-text"
                >
                  <TextLinks content={text} />
                </Text>
              ) : null}

            </div>

            <div className="col-12 pt-2 pb-3 py-md-0 col-md flex-md-grow-1 d-flex justify-content-center align-items-center">
              
              <PlayIcon 
                onClick={() => this.handleVideo()}
              />

            </div>

          </div>
        </div>
      </ComponentImageWrapper>
    )
  }

}


//Props validation
VideoIntro.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default VideoIntro