import React from 'react'
import PropTypes from 'prop-types'
import Textbox from '../Textbox'


//Component
const Textboxes = ( props ) => {

  const content = props.content
  const textboxes = ( content.textboxes ) ? content.textboxes : null

  return (
    <div className="component component-Textboxes">
      <div className="container-fluid">

        <div className="row">
          
          {textboxes ? textboxes.map( (node , index) => {
            return (
              <Textbox 
                key={index}
                font_family={content.font_family}
                heading_size={content.heading_size}
                title={node.title}
                sub_title={node.sub_title}
                content={node.text}
              />
            )
          }) : null}

        </div>
        
      </div>
    </div>
  )
}


//Props validation
Textboxes.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Textboxes