import React, {Component} from 'react'
import PropTypes from 'prop-types'
import MapContainer from '../Map'

import FlexContainerFill from '../FlexContainerFill'

//Component
class Maps extends Component {

  render() {

    const { zoom, markers } = this.props.content

    const classes = [
      'component',
      'component-Map',
    ]

    let mapMarkers = []

    if ( markers ) {
      mapMarkers = markers.map((node, index) => {

        return ({
          title: node.title,
          lat: parseFloat( node.marker.lat ),
          lng: parseFloat( node.marker.lng )
        })
      })
    }

    return (
      <div className={classes.join(' ')}>
        <FlexContainerFill>

          <MapContainer
            markers={mapMarkers}
            zoom={zoom}
          />

        </FlexContainerFill>
      </div>
    )
  }
}


//Props validation
Maps.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Maps