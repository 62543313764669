import React from 'react'
import PropTypes from 'prop-types'


//Component
const BackgroundHover = ( props ) => {

	let {image_url, darkFilter, hover, className, align_bg_x, align_bg_y} = props

  const classes = [
  	'background-hover',
  	( className ) ? className : '',
    ( darkFilter ) ? 'dark-filter' : '',
    ( hover ) ? 'hover hover--'+hover : ''
  ]

  align_bg_x = ( align_bg_x ) ? align_bg_x : 'center' 
  align_bg_y = ( align_bg_y ) ? align_bg_y : 'center' 

  const imageStyle = (image_url) ? {
    backgroundImage:    `url(${image_url})`,
    backgroundPosition: `${align_bg_x} ${align_bg_y}`,
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'cover',
    transform:          (hover === 'zoom') ? 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)' : 'translateX(-1%) translateY(0%) translateZ(0) scale(1)',
    top:                (hover === 'zoom') ? '50%' : '-1%',
    left:               (hover === 'zoom') ? '50%' : '-1%',
  } : null

  return (
    <div className={classes.join(' ')}>
    	<div className="background-hover--image" style={imageStyle}/>
    </div>
  )
}


//Props validation
BackgroundHover.propTypes = {
  className: PropTypes.string
}


//Export
export default BackgroundHover