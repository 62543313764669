import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { getServer } from '../misc/utility.js'

//Component
class LightboxContent extends Component {

  handleClick = (e) => {
    e.preventDefault()
    let _server = getServer()

    if ( _server.lightboxModal ) {

      _server.lightboxModal.openModal({
        type:     'content',
        content:  this.props.modal
      })
    }
  }


  render() {

    const { children } = this.props

    return (
      <span className="lightbox-link" onClick={e => this.handleClick(e)}>
        
        {children}

      </span>
    )
  }
}


//Props validation
LightboxContent.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.bool
}


//Export
export default LightboxContent