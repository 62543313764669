import React, {Component} from 'react'

//Threejs addons
import * as THREE from 'three'
import Cube from './Cube'


//Component
class Animation extends Component {

  componentDidMount(){

    /**GLICTH EFFECTS ARE COMMENTED AWAY THIS WAY**/

    /**let EffectComposer = require('three-full').EffectComposer**/
    /**let RenderPass = require('three-full').RenderPass**/
    /**let GlitchPass = require('three-full').GlitchPass**/

   //Basic varaibles
    this.mountWidth = window.innerWidth
    this.mountHeight = window.innerHeight
    this.cameraZ = 300
    this.documentBody = ( typeof document !== 'undefined' ) ? document.getElementsByTagName('body')[0] : null

    //Cube variables
    this.cubeQuantity = Math.floor(this.mountWidth/100)
    this.cubeMinSize = 100
    this.cubeMaxSize = 200
    this.cubeSpawn = {
      left: this.mountWidth / - 2,
      right: this.mountWidth / 2,
      bottom: this.mountHeight / - 2,
      top: this.mountHeight / 2,
      far: 50,
      near: 0
    }

    //Scene vaiables
    this.scene = new THREE.Scene()

    //Light variables
    this.ambientLight = new THREE.AmbientLight( '#ffffff', 0.2 )
    this.scene.add( this.ambientLight )

    this.directionalLight = new THREE.DirectionalLight( '#ffffff', 1 )
    this.directionalLight.position.x = this.cubeSpawn.left
    this.directionalLight.position.y = this.cubeSpawn.top
    this.directionalLight.position.z = this.cameraZ
    this.directionalLight.lookAt( this.scene.position )
    this.scene.add( this.directionalLight )

    //Camera variables
    this.camera = new THREE.OrthographicCamera(
      this.cubeSpawn.left,
      this.cubeSpawn.right,
      this.cubeSpawn.top,
      this.cubeSpawn.bottom,
      1, 
      this.cameraZ*2
    )
    this.camera.position.x = 0
    this.camera.position.y = 0
    this.camera.position.z = this.cameraZ
    this.camera.lookAt( this.scene.position )

    //Interactive variables
    this.raycaster = new THREE.Raycaster()
    this.mouse = {
      x: 999999,
      y: 999999,
    }
    this.mouseIntersected = null

    //Renderer
    this.renderer = new THREE.WebGLRenderer({ 
      antialias: true, 
      alpha : true 
    })
    this.renderer.domElement.id = "cubeCanvas"
    this.renderer.setPixelRatio( window.devicePixelRatio )
    this.renderer.setSize(this.mountWidth, this.mountHeight)

    //Postprocessing
    /**this.composer = new EffectComposer( this.renderer )
    this.renderPass = new RenderPass( this.scene, this.camera )
    this.renderPass.renderToScreen = true
    this.composer.addPass( this.renderPass )

    this.glitchTimer = 0
    this.glitchPass = new GlitchPass()
    this.glitchPass.goWild = true
    this.glitchPass.renderToScreen = false
    this.composer.addPass( this.glitchPass )**/

    //Add eventListener
    window.addEventListener('resize', this.resize)
    window.addEventListener('click', this.click)
    document.addEventListener('mousemove', this.mousemove)

    //Append canvas
    this.mount.appendChild( this.renderer.domElement )
    this.startAnimation()
  }


  componentWillUnmount(){
   //Remove canvas
    this.stopAllAnimation()
    this.mount.removeChild(this.renderer.domElement)

    //Remove document styles
    if ( this.documentBody ) {
      this.documentBody.style.cursor = 'default'
    }

    //Remove eventListener
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('click', this.click)
    document.removeEventListener('mousemove', this.mousemove)
  }


  resize = () => {

    //Update basic varaibles
    this.mountWidth = window.innerWidth
    this.mountHeight = window.innerHeight

    //Update cube variables
    this.cubeSpawn.left = this.mountWidth / - 2
    this.cubeSpawn.right = this.mountWidth / 2
    this.cubeSpawn.bottom = this.mountHeight / - 2
    this.cubeSpawn.top = this.mountHeight / 2
    
    //Update light variables
    this.directionalLight.position.x = this.cubeSpawn.left
    this.directionalLight.position.y = this.cubeSpawn.top
    
    //Update camera variables
    this.camera.left = this.cubeSpawn.left
    this.camera.right = this.cubeSpawn.right
    this.camera.top = this.cubeSpawn.top
    this.camera.bottom = this.cubeSpawn.bottom
    this.camera.updateProjectionMatrix()

    //Update renderer
    this.renderer.setSize( this.mountWidth, this.mountHeight )
  }


  click = () => {
    if ( this.mouseIntersected ) {
      //this.glitchObj = this.mouseIntersected
      //this.glitchOut()
      
      this.mouseIntersected.gravity = true
    }
  }


  mousemove = (e) => {
   e.preventDefault()
    
    this.mouse.x = ( e.clientX / this.mountWidth ) * 2 - 1
    this.mouse.y = - ( e.clientY / this.mountHeight ) * 2 + 1
  }


  /**glitchOut = () => {
    this.triggerGlitch = true;
    
    if ( this.glitchTimer >= 8 ) {
      this.glitchObj.position.y = this.cubeSpawn.top+(this.cubeMaxSize*1.5);
      this.glitchObj = null;
      this.glitchTimer = 0;
      this.triggerGlitch = false;
      
    } else {
      this.glitchTimer++;
      this.glitchId = requestAnimationFrame(this.glitchOut);
    }
  }**/


  prepareCubes = () => {

    this.cubeArray = []

    for (let i = 0; i < this.cubeQuantity; i++) {

      this.cubeArray.push( new Cube() )
      this.scene.add( 

        this.cubeArray[i].init({
          minSize: this.cubeMinSize,
          maxSize: this.cubeMaxSize,
          spawnArea: this.cubeSpawn
        })

      )
    }
  }


  renderScene = () => {
   
    //Animate cubes
    for (let i = 0; i < this.cubeArray.length; i++) {
      this.cubeArray[i].animate()
    }
    
    //Look for mouse interactions
    this.raycaster.setFromCamera(this.mouse, this.camera)
    this.intersects = this.raycaster.intersectObjects( this.scene.children )
    
    if ( this.intersects.length > 0 ) {

      if ( this.mouseIntersected !== this.intersects[ 0 ].object ) {

        if ( this.mouseIntersected ) {

          this.mouseIntersected.material.color.setHex( this.mouseIntersected.currentHex )
          //this.mouseIntersected.stayInPlace = false
        }

        this.mouseIntersected = this.intersects[ 0 ].object
        this.mouseIntersected.currentHex = this.mouseIntersected.material.color.getHex()
        this.mouseIntersected.material.color.setHex( 0x913599 )
        //this.mouseIntersected.stayInPlace = true

        if ( this.documentBody ) {
          this.documentBody.style.cursor = 'pointer'
        }
      }

    } else {

      if ( this.mouseIntersected ) {

        this.mouseIntersected.material.color.setHex( this.mouseIntersected.currentHex )
        //this.mouseIntersected.stayInPlace = false
      }

      this.mouseIntersected = null

      if ( this.documentBody ) {
        this.documentBody.style.cursor = 'default'
      }
    }
    
    //Render
    /**if ( this.triggerGlitch ) {
      this.renderPass.renderToScreen = false
      this.glitchPass.renderToScreen = true
    
    } else {
      this.renderPass.renderToScreen = true
      this.glitchPass.renderToScreen = false
    }

    this.composer.render()**/
    this.renderer.render(this.scene, this.camera)
  }


  animate = () => {
    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }


  startAnimation = () => {
   if (!this.frameId) {
      this.prepareCubes()
      this.frameId = requestAnimationFrame(this.animate)
    }
  }


  stopAllAnimation = () => {
    cancelAnimationFrame(this.frameId)
    /**cancelAnimationFrame(this.glitchId)**/
  }


  render() {

    const mount_style = {
      width: this.mountWidth,
      height: this.mountHeight
    }

    return (
      <div style={mount_style} ref={(mount) => { this.mount = mount }} className="canvas-wrapper"></div>
    )
  }
}


//Export
export default Animation