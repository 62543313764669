import React from 'react'
import PropTypes from 'prop-types'
import LinkCTA from '../LinkCTA'
import SliderFull from '../SliderFull'
import FlexContainerFill from '../FlexContainerFill'
import FlexFillInner from '../FlexFillInner'
import FullwidthItem from '../FullwidthItem'
import { imageLoadedStatus } from '../../misc/utility.js'


//Component
const CaseSlider = ( props ) => {

  const { content } = props

  return (
    <div className="component component-CaseSlider">
      <FlexContainerFill>

        <FlexFillInner>
          <SliderFull className="absolute">
          
            {content && content.cases ? ( content.cases.map( (node , index) => {

              const acf = node.acf

              const settings = {
                link: node.link,
                title: node.post_title,
                label: ( node.acf ) ? node.acf.label : null,
                align_bg_x: ( node.acf && node.acf.align_bg_x ) ? node.acf.align_bg_x : 'center',
                align_bg_y: ( node.acf && node.acf.align_bg_y ) ? node.acf.align_bg_y : 'center',
                image_url: ( acf.horizontal_image && acf.horizontal_image.localFile ) ? acf.horizontal_image.localFile.childImageSharp.fluid.src : null
              }

              //Image console
              imageLoadedStatus('CaseSlider.js', (!!settings.image_url), acf.horizontal_image.alt_text)

              return (
                <FullwidthItem key={index} {...settings} />
              )
            })) : null}

          </SliderFull>
        </FlexFillInner>

        <div className="mt-2 mt-md-negative d-flex justify-content-center">
          <LinkCTA 
            to="/cases/" 
            title={content.link_title}
            background={true}
          />
        </div>

      </FlexContainerFill>
    </div>
  )
}


//Props validation
CaseSlider.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default CaseSlider