import React from 'react'
import PropTypes from 'prop-types'
import TextLinks from './TextLinks'


//Component
const Text = ({ className, font_family, heading_size, title, sub_title, lg_text, content }) => {

  const HeadingSize = ( heading_size === 'h1' ) ? 'h2': heading_size
  let classes = [
    'text-box',
    ( className ) ? className : null
  ]

  let text_classes = [
    ( lg_text !== false ) ? 'size-lg-text' : null
  ]

  function heading() {

    let heading_classes = [
      'size-h1-heading',
      'text-left',
    ]

    if ( font_family === 'RoadRage' ) {
      heading_classes.push('font-header')

    } else {
      heading_classes.push('font-main')

    }

    if ( heading_size === 'h1' ) {
      heading_classes.push('size-h1-heading')
    }

    if ( title ) {
      return <HeadingSize className={heading_classes.join(' ')} dangerouslySetInnerHTML={{__html: title }} />

    } else {
      return null

    }
  }

  function subHeading() {

    const SubHeadingSize = 'h' + ( parseInt(HeadingSize.replace('h','')) + 1 )

    if ( sub_title ) {
      return <SubHeadingSize className="mt-1 text-left size-h2-heading font-main" dangerouslySetInnerHTML={{__html: sub_title }} />

    } else {
      return null

    }
  }

  return (
    <div className={classes.join(' ')}>
      <div className="text-box__inner">
        
        {heading()}

        {subHeading()}
        
        {(content) ? (
          <div className="mt-2">
            <TextLinks className={text_classes.join(' ')} content={content} />
          </div>
        ) : null}

      </div>
    </div>
  )
}


//Props validation
Text.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  font_family: PropTypes.string,
  heading_size: PropTypes.string,
}


//Export
export default Text